<template>
  <div :class='pageClassByNavigationComponent'
    :style='minHeightStyle'>
    <component
      :is='pageNavigationComponent'
      :sidebar-links='sidebarConfigurations.sidebarLinks'
      :sidebar-page-title='sidebarConfigurations.sidebarPageTitle'
      :custom-background-stype='sidebarConfigurations.customBackgroundStyle' />
    <div class='flex-grow relative px-4 lg:px-0'>
      <welcome-conference-banner v-if='showConferenceBanner' class='mb-8 lg:mb-12' />
      <div class='py-8 lg:py-16'>
        <h1 class='text-3xl font-bold mb-4 lg:mb-8'>{{ pageTitle }}</h1>
        <div v-if='topHtmlContent' v-html='topHtmlContent'></div>
        <div v-for='formField in signupFormFields'
          :key='formField.keyName'
          class='mb-6 w-full lg:w-2/3' >
          <edit-form-field
            :edit-form-field='formField'
            :show-requirement-indicator-on-top='showRequirementIndicatorOnTop'
            :is-horizontal-layout='isFormFieldsHorizontalLayout'
            :value='fieldValue(formField.category, formField.keyName)'
            :requirement-indicator-helper-text='requirementIndicatorHelperText'
            @update-edit-form-field-value='updateSignupFormFieldValue(formField.category, formField.keyName, ...arguments)' />
          <div v-if="formField.keyName === 'is_icola_member' && membershipData.isIcolaMember === '한국지질동맥경화학회'"
            class='bg-gray-50 p-4 mt-2'>
            <p class='text-lg font-semibold' style='color: #744d7d;'>한국지질동맥경화학회 회원 정보로 간편 가입</p>
            <p class='font-semibold mb-2' style='color: #744d7d;'>등록 시 한국지질동맥경화학회 회원혜택을 받으시려면 회원인증이 필요합니다.</p>
            <div class='flex flex-row justify-start gap-x-2 mb-2'>
              <div class='w-1/3'>
                <label class='uppercase text-sm text-gray-900 flex-shrink-0 p-2'>
                  KSoLA ID<span class='text-red-600'>*</span>
                </label>
                <input
                  v-model='ksolaId'
                  @input='blockKoreanInput($event)'
                  class='border py-1 px-2 rounded block w-full'>
              </div>
              <div class='w-1/3'>
                <label class='uppercase text-sm text-gray-900 flex-shrink-0 p-2'>
                  KSoLA PW<span class='text-red-600'>*</span>
                </label>
                <input
                  type='password'
                  v-model='ksolaPassword'
                  class='border py-1 px-2 rounded block w-full'>
              </div>
            </div>
            <div class='flex flex-row justify-between'>
              <div v-if='verifiedKsolaLogin' style='color: #744d7d;'>
                <circle-check-icon class='inline-block mr-2' />회원인증이 완료되었습니다.
              </div>
              <button 
                v-else
                class='border py-2 px-16 rounded block submit-button'
                :disabled='disabledKsolaLoginButton'
                :class='disabledKsolaLoginButtonClass'
                @click='ksolaLogin'>
                회원인증
              </button>
              <a target='_blank'
                href='https://www.lipid.or.kr/member/find_id_and_pw.php'
                class='text-sm text-gray-700 rounded block underline flex flex-col justify-end'>
                KSoLA 회원 ID/PW 찾기
              </a>
            </div>
          </div>
        </div>
        <div v-for='(term, index) in terms'
          :key='`term-${index}`'
          class='text-xs border rounded-md mb-6 w-full lg:w-2/3'>
          <div class='bg-gray-50 p-4 overflow-y-auto max-h-56' v-html='term.text'></div>
          <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-sm'>
            <input type='checkbox' :name='`checkbox-${index}`' v-model='term.agreed'>
            <span class='px-1'>{{ term.confirmationText }}</span>
          </label>
        </div>
        <button 
          class='px-4 py-3 hover:shadow-md uppercase border rounded-md submit-button w-full lg:w-1/2 mb-6'
          @click='submitSignup'
          :disabled='disabledSignupButton'
          :class='disabledSignupButtonClass'>
          {{ signupButtonText }}
        </button>
        <ul v-if='!showRequirementIndicatorOnTop'
          class='list-disc list-inside text-red-600 text-sm'>
          <div v-for='formField in signupFormFields'
            :key='formField.keyName'>
            <li v-if='isFieldValutEmpty(formField)'>
              {{ formField.name }} {{requirementIndicatorHelperText}}
            </li>
          </div>
        </ul>
        <ul class='list-disc list-inside text-red-600 text-sm'>
          <div v-for='requirement in additionalRequirementsIndicators'
            :key='requirement.keyName'>
            <li>
              {{ requirement.metaData.indicatorText }}
            </li>
          </div>
        </ul>
      </div>
      <welcome-sponsor-carousel  v-if='showSponsorCarousel'  class='max-w-4xl mt-16 lg:mt-20'/>
    </div>
  </div>
</template>

<script>
import { CircleCheckIcon } from 'vue-tabler-icons'
import { mapState, mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import camelCase from 'lodash/camelCase'
import { mapFields } from 'vuex-map-fields'
import jsonLogic  from 'json-logic-js'
import dayjs      from 'dayjs'

export default {
  name: 'Signup',
  components: {
    CircleCheckIcon,
    'tabs-page-navigation': () => import('@/components/common/TabsPageNavigation'),
    'sidebar-page-navigation': () => import('@/components/common/SidebarPageNavigation'),
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      verifiedKsolaLogin: false,
      ksolaId: '',
      ksolaPassword: '',
      validPhoneNumbers: [],
    }
  },
  watch: {
    'termsDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.terms = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'userDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.signupParams = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'profileDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.profile = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'registrationDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.registrationData = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'membershipDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.membershipData = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'membershipData.isIcolaMember': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.verifiedKsolaLogin = false
          this.ksolaId = ''
          this.ksolaPassword = ''
        }
      },
    }
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'profile',
      'terms',
      'registrationData',
      'membershipData',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'showingEventSignupConfigurations',
      'eventMainThemeColor',
    ]),
    disabledKsolaLoginButton () {
      return !this.ksolaId ||
             !this.ksolaPassword ||
             this.verifiedKsolaLogin
    },
    disabledKsolaLoginButtonClass () {
      return this.disabledKsolaLoginButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    pageNavigationComponent () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.pageNavigationComponent) ? this.showingEventSignupConfigurations.pageNavigationComponent : ''
    },
    pageClassByNavigationComponent () {
      return this.pageNavigationComponent === 'sidebar-page-navigation' ? 'flex flex-col lg:flex-row justify-between items-stretch gap-x-16' : ''
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    pageTitle () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.pageTitle) ? this.showingEventSignupConfigurations.pageTitle : 'Sign up'
    },
    signupButtonText () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.signupButtonText) ? this.showingEventSignupConfigurations.signupButtonText : 'Submit Signup'
    },
    topHtmlContent () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.topHtmlContent) ? this.showingEventSignupConfigurations.topHtmlContent : ''
    },
    userDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.userDefault) ? this.showingEventSignupConfigurations.userDefault : {}
    },
    termsDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.termsDefault) ? this.showingEventSignupConfigurations.termsDefault : []
    },
    profileDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.profileDefault) ? this.showingEventSignupConfigurations.profileDefault : {}
    },
    registrationDataDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.registrationDataDefault) ? this.showingEventSignupConfigurations.registrationDataDefault : {}
    },
    membershipDataDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.membershipDataDefault) ? this.showingEventSignupConfigurations.membershipDataDefault : {}
    },
    currentDateTimeString () {
      return dayjs().format('YYYYMMDDHHmm')
    },
    signupFormFields () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.signupFormFields) ?
        this.showingEventSignupConfigurations.signupFormFields.concat()
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    sidebarConfigurations () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.sidebarConfigurations) ? this.showingEventSignupConfigurations.sidebarConfigurations : {}
    },
    isFormFieldsHorizontalLayout () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.isFormFieldsHorizontalLayout) ? this.showingEventSignupConfigurations.isFormFieldsHorizontalLayout : false
    },
    showRequirementIndicatorOnTop () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.showRequirementIndicatorOnTop) ? this.showingEventSignupConfigurations.showRequirementIndicatorOnTop : false
    },
    requirementIndicatorHelperText () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.requirementIndicatorHelperText) ? this.showingEventSignupConfigurations.requirementIndicatorHelperText : 'is required.'
    },
    additionalRequirementIndicatorsHtml () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.additionalRequirementIndicatorsHtml) ? this.showingEventSignupConfigurations.additionalRequirementIndicatorsHtml : ''
    },
    signupErrorDoneMessages () {
      return (this.showingEventSignupConfigurations.signupDoneAlertMessage) ? this.showingEventSignupConfigurations.signupDoneAlertMessage : 'signup is complete'
    },
    signupErrorAlertMessages () {
      return (this.showingEventSignupConfigurations.signupErrorAlertMessages) ? this.showingEventSignupConfigurations.signupErrorAlertMessages : []
    },
    signupGeneralErrorAlertMessage () {
      return this.signupErrorAlertMessages.length > 0 &&
             this.signupErrorAlertMessages.find(message => message.error === 'general') ? 
             this.signupErrorAlertMessages.find(message => message.error === 'general').message : 'Cannot submit your signup. Please contact us.'
    },
    needToCheckEmailFormat () {
      let mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
      return this.signupParams.email && !mailformat.test(this.signupParams.email)
    },
    needToCheckPasswordConfirmation () {
      return this.signupParams.password !== this.signupParams.passwordConfirmation
    },
    needToCheckPhoneNumber () {
      return !this.validPhoneNumbers.every(phoneNumber => phoneNumber.isValidPhoneNumber)
    },
    needToAgreedTerms () {
      return !this.terms.filter(term => term.required).every(term => term.agreed)
    },
    needToKsolaLogin () {
      return this.membershipData.isIcolaMember === '한국지질동맥경화학회' && !this.verifiedKsolaLogin
    },
    additionalRequirements () {
      return {
        needToCheckEmailFormat: this.needToCheckEmailFormat,
        needToCheckPasswordConfirmation: this.needToCheckPasswordConfirmation,
        needToCheckPhoneNumber: this.needToCheckPhoneNumber,
        needToAgreedTerms: this.needToAgreedTerms,
        needToKsolaLogin: this.needToKsolaLogin,
      }
    },
    additionalRequirementsIndicators () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.additionalRequirementsIndicators) ?
        this.showingEventSignupConfigurations.additionalRequirementsIndicators.concat()
        .filter((requirement) => {
          let condition = requirement.metaData.showCondition ? requirement.metaData.showCondition : false
          return jsonLogic.apply(condition, {additionalRequirements: this.additionalRequirements})
        }) : []
    },
    disabledSignupButton () {
      return this.signupFormFields.map(field => this.fieldValue(field.category, field.keyName)).some(value => !value ||
        typeof value === 'string' && !value ||
        typeof value === 'object' && !Object.keys(value).length ||
        Array.isArray(value) && !value.length) ||
      Object.values(this.additionalRequirements).some(requirement => requirement)
    },
    disabledSignupButtonClass () {
      return this.disabledSignupButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    ksolaLoginButtonText () {
      return this.verifiedKsolaLogin ? '' : ''
    },
  },
  methods: {
    ...mapActions('users', [
      'signup',
      'checkDuplicatedPhoneNumber',
      'patchTerms',
      'patchMembershipData',
      'patchRegistrationData',
      'createProfile',
      'checkTokenStatus',
      'getKsolaLogin',
    ]),
    ...mapActions('zandiWebhooks', [
      'sendZandiWebhookMessage'
    ]),
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'profile') {
        if (arr.length === 2) {
          if (keyName.includes('phone_number')) { 
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            val = index > -1 && this.profile[arr[0]][arr[1]] ? {
              phoneNumber: this.validPhoneNumbers[index].phoneNumber,
              isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else {
            val = this.profile[arr[0]][arr[1]]
          }
        } else if (arr.length === 1) {
          if (keyName === 'address') {
            val = this.profile.address && this.profile.postalCode ? {
              address: this.profile.address,
              postalCode: this.profile.postalCode,
            } : null
          } else if (keyName === 'phone_number') {
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === 'phone_number')
            val = index > -1 && this.profile[arr[0]] ? {
                phoneNumber: this.validPhoneNumbers[index].phoneNumber,
                isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else if (keyName === 'organization_hospital_name') {
            val = this.profile.organization && this.profile.hospitalName ? {
              organization: this.profile.organization,
              hospitalName: this.profile.hospitalName,
            } : null
          } else {
            val = this.profile[arr[0]]
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          val = this.signupParams[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.signupParams[arr[0]]
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          val = this.registrationData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.registrationData[arr[0]]
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          val = this.membershipData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.membershipData[arr[0]]
        }
      }
      return val
    },
    updateSignupFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'profile') {
        if (arr.length === 2) { //extra info
          if (keyName.includes('phone_number')) { 
            this.profile[arr[0]][arr[1]] = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else {
            this.profile[arr[0]][arr[1]] = value
          }
        } else if (arr.length === 1) {
          if (keyName === 'phone_number') {
            this.profile.phoneNumber = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else if (keyName === 'address') {
            this.profile.address = value.address
            this.profile.postalCode = value.postalCode
          } else if (keyName === 'organization_hospital_name') {
            this.profile.organization = value.organization
            this.profile.hospitalName = value.hospitalName
          } else {
            this.profile[arr[0]] = value
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          this.signupParams[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.signupParams[arr[0]] = value
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          this.registrationData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.registrationData[arr[0]] = value
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          this.membershipData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.membershipData[arr[0]] = value
        }
      }
    },
    signupErrorMessage (error) {
      let errorMessagePair = this.signupErrorAlertMessages.find(message => message.error === error)
      let alertMessage = errorMessagePair ? errorMessagePair.message : this.loginGeneralErrorAlertMessage
      this.$alert(alertMessage, {
        type: 'warning',
        confirmButtonText: 'Close'
      })
    },
    submitSignup () {
      this.sendZandiWebhookMessage({
        messageType: 'icola_signup',
        message: `{
          signupParams: ${JSON.stringify(this.signupParams)},
          membershipData: ${JSON.stringify(this.membershipData)},
          registrationData: ${JSON.stringify(this.registrationData)},
          profile: ${JSON.stringify(this.profile)}
        }`
      })
      this.signup().then(() => {
        let patchTerms = this.patchTerms()
        let patchMembershipData = this.patchMembershipData(this.membershipData)
        let patchRegistrationData = this.patchRegistrationData(this.registrationData)
        let createProfile = this.createProfile()
    
        Promise.all([patchTerms, patchMembershipData, createProfile, patchRegistrationData]).then(() => {
          this.$alert(this.signupErrorDoneMessages, {
            confirmButtonText: 'OK',
            type: 'success',
            showClose: false,
          }).then(() => {
            this.$router.push({name: 'Main'})
          }).catch(() => {})
        })
      }).catch((error) => {
        this.signupErrorMessage(error.response.data.error)
      })
    },
    ksolaLogin () {
      this.getKsolaLogin({
        id: this.ksolaId,
        password: this.ksolaPassword
      }).then(resp => {
        this.$message({
          type: 'success',
          message: '한국지질동맥경화학회 회원인증이 완료되었습니다.'
        })
        this.verifiedKsolaLogin = true
        this.signupParams.email = resp.email
        this.profile.name = resp.name
        this.profile.hospitalName = resp.officeName
        this.profile.birthDate = resp.birthday
        let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === 'phone_number')
        if (index > -1) {
          this.validPhoneNumbers[index].phoneNumber = resp.phone
          this.validPhoneNumbers[index].isValidPhoneNumber = true
        } else {
          this.validPhoneNumbers.push({
            keyName: 'phone_number',
            phoneNumber: resp.phone,
            isValidPhoneNumber: true
          })
        }
        this.profile.phoneNumber = resp.phone
      }).catch(() => {
        this.$alert('한국지질동맥경화학회 아이디 또는 비밀번호를 잘못 입력했습니다. 입력하신 내용을 다시 확인해주세요.' , {
          type: 'warning',
          confirmButtonText: 'Close'
        })
      })
    },
    isFieldValutEmpty (field) {
      let value = this.fieldValue(field.category, field.keyName)
      return !value ||
        typeof value === 'string' && !value ||
        typeof value === 'object' && !Object.keys(value).length ||
        Array.isArray(value) && !value.length
    },
    blockKoreanInput (event) {
      event.target.value = event.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig, '')
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.$router.replace({name: 'Main'}).catch(() => {})
      }
    })
  },
}
</script>

<style type='scss' scoped>
.submit-button {
  @apply text-white;
  background-color: var(--eventMainColor);
}

.background-style {
  background: transparent; 
  width: 100%;
}
.sidebar-navigation-links .router-link-active {
  @apply font-bold;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>
